<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Vídeos" />
  <section class="cart-area pt-100 pb-100">
    <div class="container">
      <div class="row">

        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        <div class="col-4"><iframe width="560" height="315" src="https://www.youtube.com/embed/EvrJRcxp4kQ?si=STQvh_wtPHCtd6bN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>

      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'VideosPage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

