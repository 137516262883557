<template>
 <section class="contact__area grey-bg-2 pt-120 pb-90 p-relative fix">
   <div class="contact__shape">
      <img class="contact-shape-5" src="../../assets/img/contact/contact-shape-5.png" alt="">
      <img class="contact-shape-4" src="../../assets/img/contact/contact-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-5 offset-lg-1 col-md-6">
            <div class="contact__item text-center mb-30 transition-3 white-bg">
               <div class="contact__icon d-flex justify-content-center align-items-end">
                  <svg viewBox="0 0 24 24">
                     <circle class="st0" cx="12" cy="12" r="10"/>
                     <path class="st0" d="M8,14c0,0,1.5,2,4,2s4-2,4-2"/>
                     <line class="st0" x1="9" y1="9" x2="9" y2="9"/>
                     <line class="st0" x1="15" y1="9" x2="15" y2="9"/>
                  </svg>
               </div>
               <div class="contact__content">
                  <h3 class="contact__title">Knowledge Base</h3>
                  <p>My good sir plastered cuppa barney cobblers mush argy bargy ruddy.</p>
                  <router-link to="/contact" class="e-btn e-btn-border">Visit Documentation</router-link>
               </div>
            </div>
         </div>
         <div class="col-xxl-5 col-xl-5  col-lg-5 col-md-6">
            <div class="contact__item text-center mb-30 transition-3 white-bg">
               <div class="contact__icon d-flex justify-content-center align-items-end">
                  <svg viewBox="0 0 24 24">
                     <path class="st0" d="M21,10.8c0,1.3-0.3,2.6-0.9,3.8c-1.4,2.9-4.4,4.7-7.6,4.7c-1.3,0-2.6-0.3-3.8-0.9L3,20.3l1.9-5.7  C4.3,13.4,4,12.1,4,10.8c0-3.2,1.8-6.2,4.7-7.6c1.2-0.6,2.5-0.9,3.8-0.9H13c4.3,0.2,7.8,3.7,8,8V10.8z"/>
                     <g>
                        <circle class="st1" cx="9.3" cy="10.5" r="0.5"/>
                        <circle class="st1" cx="12.5" cy="10.5" r="0.5"/>
                        <circle class="st1" cx="15.7" cy="10.5" r="0.5"/>
                     </g>
                     </svg>
               </div>
               <div class="contact__content">
                  <h3 class="contact__title">Online Support</h3>
                  <p>My good sir plastered cuppa barney cobblers mush argy bargy ruddy.</p>
                  <router-link to="/contact" class="e-btn e-btn-border">Send a Ticket</router-link>
               </div>
            </div>
         </div>
      </div>
   </div>
         </section>
</template>

<script>
export default {
  name: "ContactInfo",
};
</script>
