<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Tutoriais" />
  <BlogArea/>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import BlogArea from "@/components/Blog/BlogArea.vue";

export default {
  name:'TutoriaisPage',
  components:{
    Breadcrumb,
    Header,
    Footer,BlogArea,
  }
}
</script>

