<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Api" />
  <section class="cart-area pt-100 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4>core_usuarios</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/core_usuarios" target="_blank">core_usuarios</a>
          <h4>v1_config</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_config" target="_blank">v1_config</a>
          <h4>v1_crud</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_crud" target="_blank">v1_crud</a>
          <h4>v1_crud_php</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_crud_php" target="_blank">v1_crud_php</a>
          <h4>v1_depoimentos</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_depoimentos" target="_blank">v1_depoimentos</a>
          <h4>v1_locations</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_locations" target="_blank">v1_locations</a>
          <h4>v1_payments</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_payments" target="_blank">v1_payments</a>
          <h4>v1_peoples</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_peoples" target="_blank">v1_peoples</a>
          <h4>v1_post</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_post" target="_blank">v1_post</a>
          <h4>v1_schools</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_schools" target="_blank">v1_schools</a>
          <h4>v1_templates</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_templates" target="_blank">v1_templates</a>
          <h4>v1_users</h4>
          <a class="btn btn-link" href="https://us-central1-gestio-school-dev.cloudfunctions.net/v1_users" target="_blank">v1_users</a>


        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'ApiPage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

