import {createWebHistory, createRouter} from "vue-router";
import Home from '../pages/Home.vue';
import Courses from '../pages/Courses.vue';
import CoursesList from '../pages/CoursesList.vue';
import CourseSidebar from '../pages/CourseSidebar.vue';
import CourseDetails from '../pages/CourseDetails.vue';
import Blog from '../pages/Blog.vue';
import BlogDetails from '../pages/BlogDetails.vue';
import About from '../pages/About.vue';
import Instructor from '../pages/Instructor.vue';
import InstructorDetails from '../pages/InstructorDetails.vue';
import EventDetails from '../pages/EventDetails.vue';
import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';
import SignIn from '../pages/SignIn.vue';
import SignUp from '../pages/SignUp.vue';
import ErrorPage from '../pages/ErrorPage.vue';
import Contact from '../pages/Contact.vue';
import CourseDetailsPage from '../pages/DynamicCourseDetails.vue';
import DynamicBlogDetails from '../pages/DynamicBlogDetails.vue';

import Secretaria from "../pages/Secretaria.vue";
import Pedagogico from "../pages/Pedagogico.vue";
import Plataforma from "../pages/Plataforma.vue";
import Escolas from "../pages/Escolas.vue";
import Videos from "@/pages/Videos.vue";
import Tutoriais from "@/pages/Tutoriais.vue";
import Api from "@/pages/Api.vue";
import QuemSomos from "@/pages/QuemSomos.vue";
import Planos from "@/pages/Planos.vue";
import Contato from "@/pages/Contato.vue";
import Ajuda from "@/pages/Ajuda.vue";
import Suporte from "@/pages/Suporte.vue";
import Termos from "@/pages/Termos.vue";
import PoliticaDePrivacidade from "@/pages/PoliticaDePrivacidade.vue";
import Sitemap from "@/pages/Sitemap.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Home,
        },
        {
            path: '/home',
            component: Home,
        },
        { path: '/secretaria', component: Secretaria },
        { path: '/pedagogico', component: Pedagogico },
        { path: '/plataforma', component: Plataforma },
        { path: '/escolas', component: Escolas },
        { path: '/videos', component: Videos },
        { path: '/tutoriais', component: Tutoriais },
        { path: '/api', component: Api },
        { path: '/quem-somos', component: QuemSomos },
        { path: '/planos', component: Planos },
        { path: '/contato', component: Contato },
        { path: '/ajuda', component: Ajuda },
        { path: '/suporte', component: Suporte },
        { path: '/termos', component: Termos },
        { path: '/politica-de-privacidade', component: PoliticaDePrivacidade },
        { path: '/sitemap', component: Sitemap },
        {
            path: '/courses',
            component: Courses,
        },
        {
            path: '/courses-list',
            component: CoursesList,
        },
        {
            path: '/courses-sidebar',
            component: CourseSidebar,
        },
        {
            path: '/course-details',
            component: CourseDetails,
        },
        {
            path: '/blog',
            component: Blog,
        },
        {
            path: '/blog-details',
            component: BlogDetails,
        },
        {
            path: '/about',
            component: About,
        },
        {
            path: '/instructor',
            component: Instructor,
        },
        {
            path: '/instructor-details',
            component: InstructorDetails,
        },
        {
            path: '/event-details',
            component: EventDetails,
        },
        {
            path: '/cart',
            component: Cart,
        },
        {
            path: '/carrinho',
            component: Checkout,
        },
        {
            path: '/checkout',
            component: Checkout,
        },
        {
            path: '/login',
            component: SignIn,
        },
        {
            path: '/register',
            component: SignUp,
        },
        {
            path: '/errorPage',
            component: ErrorPage,
        },
        {
            path: '/contact',
            component: Contact,
        },
        {
            path: '/course-details/:id',
            component: CourseDetailsPage,
        },
        {
            path: '/blog-details/:id',
            component: DynamicBlogDetails,
        },
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router