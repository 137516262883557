<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Política de privacidade" />
  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">


            <p>Última atualização: 01/11/2023</p>
            <p>A sua privacidade é importante para nós. Esta Política de Privacidade explica como nós, Gestio School, coletamos, utilizamos, compartilhamos e protegemos as informações pessoais dos usuários de nosso Sistema de Gestão Escolar, em conformidade com a Lei Geral de Proteção de Dados (LGPD).</p>
            <p><strong>1. Informações Pessoais Coletadas</strong></p>
            <p>Coletamos informações pessoais para fornecer e aprimorar nossos serviços. As informações pessoais podem incluir, mas não estão limitadas a:</p>
            <p>Nome completo</p>
            <p>Data de nascimento</p>
            <p>Endereço de e-mail</p>
            <p>Número de telefone</p>
            <p>Informações acadêmicas</p>
            <p>Foto</p>
            <p>Outras informações necessárias para a prestação dos serviços solicitados</p>
            <p><strong>2. Uso das Informações Pessoais</strong></p>
            <p>Utilizamos as informações pessoais coletadas para os seguintes propósitos:</p>
            <p>Fornecer e manter nossos serviços de Sistema de Gestão Escolar</p>
            <p>Personalizar a experiência do usuário e oferecer suporte mais eficiente</p>
            <p>Comunicar-se com os usuários, inclusive para responder a dúvidas e fornecer informações relevantes</p>
            <p>Realizar análises e melhorar nossos serviços</p>
            <p>Cumprir obrigações legais ou regulatórias</p>
            <p><strong>3. Compartilhamento de Informações Pessoais</strong></p>
            <p>Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes situações:</p>
            <p>Com o consentimento explícito do usuário</p>
            <p>Para cumprir obrigações legais ou regulatórias</p>
            <p>Para garantir a segurança e a integridade do sistema</p>
            <p>Para processamento e armazenamento de dados em conformidade com nossos parceiros de serviços</p>
            <p><strong>4. Proteção e Armazenamento de Dados</strong></p>
            <p>Adotamos medidas técnicas e organizacionais para proteger as informações pessoais contra acesso não autorizado ou divulgação. Os dados pessoais são armazenados de forma segura em servidores controlados e seguros.</p>
            <p><strong>5. Direitos dos Titulares de Dados</strong></p>
            <p>De acordo com a LGPD, o usuário tem os seguintes direitos sobre suas informações pessoais:</p>
            <p>Confirmar a existência de tratamento de dados</p>
            <p>Acesso aos dados</p>
            <p>Correção de dados incompletos, inexatos ou desatualizados</p>
            <p>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a lei</p>
            <p>Portabilidade dos dados a outro fornecedor de serviço</p>
            <p>Eliminação dos dados pessoais tratados com o consentimento do titular</p>
            <p>Informação das entidades públicas e privadas com as quais compartilhamos dados</p>
            <p>Revogação do consentimento</p>
            <p><strong>6. Alterações a esta Política de Privacidade</strong></p>
            <p>Esta Política de Privacidade pode ser atualizada periodicamente para refletir mudanças em nossas práticas de privacidade. Recomendamos que os usuários revisem esta política regularmente para estar informados sobre como protegemos suas informações.</p>
            <p><strong>7. Contato</strong></p>
            <p>Se tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco pelo e-mail [endereço de e-mail de contato].</p>
            <p>Ao utilizar nosso Sistema de Gestão Escolar, você concorda com os termos e condições desta Política de Privacidade.</p>
            <p>Esta Política de Privacidade é efetiva a partir da data de sua última atualização.</p>

        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'PoliticaDePrivacidadePage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

