<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow "/>
  <Breadcrumb title="Planos"/>
  <section class="cart-area pt-100 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div class="your-order">

            <TablePlano title="Plano 1"  />
          </div>
        </div>
        <div class="col-4">
          <div class="your-order">
            <TablePlano qtd="2.5" title="Plano 2"  />
          </div>
        </div>
        <div class="col-4">
          <div class="your-order">
            <TablePlano qtd="5" title="Plano 3"  />
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import TablePlano from "@/components/TablePlano.vue";

export default {
  name: 'PlanosPage',
  components: {
    Breadcrumb,
    Header,
    Footer,
    TablePlano
  }
}
</script>

