<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Termos" />
  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">


            <p>Estes Termos de Uso ("Termos") regem o uso da plataforma de Sistema de Gestão Escolar oferecida por [Nome da Empresa] ("nós", "nosso", "empresa"). Ao acessar e utilizar nossa plataforma, você ("usuário") concorda em cumprir estes Termos. Se você não concordar com estes Termos, por favor, não utilize nossa plataforma.</p>
            <p>1. Aceitação dos Termos</p>
            <p>Ao acessar e utilizar nossa plataforma, você declara ter pelo menos 18 anos de idade ou ser considerado maior de idade em sua jurisdição e aceita estes Termos em sua totalidade. Se estiver aceitando estes Termos em nome de uma organização, você declara estar autorizado a vincular legalmente tal organização a estes Termos.</p>
            <p>2. Uso da Plataforma</p>
            <p><strong>Cadastro e Credenciais de Acesso:</strong></p>
            <p>Para utilizar nossa plataforma, você pode precisar criar uma conta. Ao fazer isso, você concorda em fornecer informações precisas e atualizadas. Você é responsável por manter a confidencialidade de suas credenciais de acesso.</p>
            <p><strong>Uso Adequado:</strong></p>
            <p>Você concorda em usar a plataforma de maneira ética, legal e responsável, cumprindo todas as leis e regulamentações aplicáveis. Você não deve usar a plataforma para atividades fraudulentas, difamatórias, prejudiciais, invasivas ou que violem os direitos de terceiros.</p>
            <p><strong>Propriedade Intelectual:</strong></p>
            <p>Nossa plataforma, incluindo todos os conteúdos, funcionalidades, design e marcas registradas, são de propriedade exclusiva da empresa. Você concorda em não copiar, modificar, distribuir ou criar trabalhos derivados da plataforma sem permissão prévia por escrito.</p>
            <p><strong>Atualizações e Modificações:</strong></p>
            <p>Reservamo-nos o direito de atualizar, modificar ou descontinuar parte ou a totalidade da plataforma a nosso critério. Informaremos os usuários sobre mudanças significativas por meio de aviso na plataforma.</p>
            <p>3. Responsabilidades</p>
            <p><strong>Precisão das Informações:</strong></p>
            <p>Você é responsável pela precisão e veracidade das informações fornecidas através da plataforma. A empresa não se responsabiliza por informações incorretas ou desatualizadas.</p>
            <p><strong>Backup de Dados:</strong></p>
            <p>Você é responsável por fazer backups regulares de seus dados e informações. A empresa não se responsabiliza pela perda de dados.</p>
            <p><strong>Interações com Outros Usuários:</strong></p>
            <p>Você é exclusivamente responsável por suas interações com outros usuários. A empresa não se responsabiliza por quaisquer danos resultantes dessas interações.</p>
            <p>4. Limitação de Responsabilidade</p>
            <p><strong>Isenção de Responsabilidade:</strong></p>
            <p>A empresa não garante que a plataforma estará livre de erros ou indisponibilidades. Você concorda que o uso da plataforma é por sua própria conta e risco.</p>
            <p><strong>Danos Indiretos:</strong></p>
            <p>Em nenhuma circunstância a empresa será responsável por danos indiretos, consequenciais, especiais, incidentais ou punitivos, incluindo perda de lucros ou dados.</p>
            <p>5. Disposições Gerais</p>
            <p><strong>Lei Aplicável:</strong></p>
            <p>Estes Termos são regidos e interpretados de acordo com as leis do [país ou estado]. Qualquer litígio será submetido à jurisdição exclusiva dos tribunais competentes nesta jurisdição.</p>
            <p><strong>Alterações nos Termos:</strong></p>
            <p>Reservamo-nos o direito de modificar ou revisar estes Termos a qualquer momento, publicando as alterações na plataforma. O uso contínuo da plataforma após tais modificações constitui sua aceitação dos Termos atualizados.</p>
            <p>Ao utilizar nossa plataforma, você concorda com estes Termos de Uso. Se tiver dúvidas ou preocupações, entre em contato conosco através do e-mail [endereço de e-mail de contato].</p>
            <p>Estes Termos de Uso são efetivos a partir da data de sua última atualização</p>


        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'TermosPage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

