<template>
 <section class="contact__area pt-115 pb-120">
   <div class="container">
      <div class="row">
         <div class="col-xxl-7 col-xl-7 col-lg-6">
            <div class="contact__wrapper">
               <div class="section__title-wrapper mb-40">
                  <h2 class="section__title">Fale <span class="yellow-bg yellow-bg-big">conosco<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                  <p>Responderemos o mais breve possivel.</p>
                   <p class="black-color">Bem-vindo à nossa Página de Contato! Estamos aqui para ouvir você e fornecer toda a assistência necessária em relação ao nosso Sistema de Gestão Escolar. Fique à vontade para entrar em contato conosco por meio das opções abaixo:
                   </p>
               </div>
               <div class="contact__form">
                  <form action="assets/mail.php">
                     <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input type="text" placeholder="Nome e sobrenome" name="name">
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input type="email" placeholder="E-mail" name="email">
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <input type="text" placeholder="Assunto" name="subject">
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <textarea placeholder="Deixe uma mensagem" name="message"></textarea>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-agree  d-flex align-items-center mb-20">
                              <input class="e-check-input" type="checkbox" id="e-agree">
                              <label class="e-check-label" for="e-agree">Eu concordo com os <a href="#">Termos & Condições</a></label>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__btn">
                              <button type="submit" class="e-btn">Enviar mensagem</button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <div class="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
            <div class="contact__info white-bg p-relative z-index-1">
               <div class="contact__shape">
                  <img class="contact-shape-1" src="../../assets/img/contact/contact-shape-1.png" alt="">
                  <img class="contact-shape-2" src="../../assets/img/contact/contact-shape-2.png" alt="">
                  <img class="contact-shape-3" src="../../assets/img/contact/contact-shape-3.png" alt="">
               </div>
               <div class="contact__info-inner white-bg">
                  <ul>
                     <li>
                        <div class="contact__info-item d-flex align-items-start mb-35">
                           <div class="contact__info-icon mr-15">
                              <svg class="mail" viewBox="0 0 24 24">
                                 <path class="st0" d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z"/>
                                 <polyline class="st0" points="22,6 12,13 2,6 "/>
                              </svg>
                           </div>
                           <div class="contact__info-text">
                              <h4>Você pode enviar um e-mail direto</h4>
                              <p><a href="mailto:contato@gestio.school">contato@gestio.school</a></p>
                              <p><a href="mailto:ouvidoria@gestio.school"> ouvidoria@gestio.school</a></p>
                           </div>
                        </div>
                     </li>
                     <li>
                        <div class="contact__info-item d-flex align-items-start mb-35">
                           <div class="contact__info-icon mr-15">
                              <svg class="call" viewBox="0 0 24 24">
                                 <path class="st0" d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z"/>
                                 </svg>
                           </div>
                           <div class="contact__info-text">
                              <h4>Contato</h4>
                              <p><a href="tel:+(426)-742-26-44">+(426) 742 26 44</a></p>
                              <p><a href="tel:+(224)-762-442-32">+(224) 762 442 32</a></p>
                           </div>
                        </div>
                     </li>
                  </ul>

               </div>
            </div>
         </div>
      </div>
   </div>
 </section>
</template>

<script>
export default {
   name:'ContactArea',
};
</script>

