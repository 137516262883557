<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Plataforma" />
  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">


            <p>Nosso Sistema de Gestão Escolar representa uma revolução na administração educacional, oferecendo uma gama de vantagens que impulsionam a eficiência, transparência e excelência nas escolas. Estamos comprometidos em transformar a maneira como as instituições de ensino gerenciam suas operações diárias, proporcionando um ambiente mais produtivo e enriquecedor para alunos, professores, pais e a equipe administrativa. Aqui estão as vantagens inigualáveis que você pode esperar ao adotar nossa solução:</p>

            <div class="row">
                <div class="col-3">
                    <h3>Agilidade e Automatização de Processos:</h3>
                    <p>Simplificamos tarefas administrativas complexas, como matrículas, registro de presença, gestão de horários e geração de relatórios, através da nossa abordagem inovadora de automatização. Isso permite que a equipe escolar economize tempo valioso e se concentre em atividades mais estratégicas.</p>
                </div>
                <div class="col-3">
                    <h3>Integração e Centralização de Dados:</h3>
                    <p>Centralizamos todas as informações relevantes em um único sistema, tornando o acesso e a análise de dados mais eficientes. Isso facilita a tomada de decisões informadas, a avaliação do desempenho acadêmico e a implementação de estratégias para o aprimoramento contínuo.</p>
                </div>
                <div class="col-3">
                    <h3>Comunicação Eficaz e Transparência:</h3>
                    <p>Melhoramos a comunicação entre escola, pais e alunos, oferecendo um canal de comunicação direto e instantâneo. Os pais podem acompanhar o progresso acadêmico de seus filhos, receber atualizações e participar ativamente do processo educacional.</p>
                </div>
                <div class="col-3">
                    <h3>Gestão Financeira Otimizada:</h3>
                    <p>Facilitamos o controle das finanças escolares, incluindo mensalidades, taxas e orçamentos. Nossa plataforma oferece funcionalidades que ajudam a monitorar as transações financeiras, otimizar a cobrança e manter um registro claro das operações financeiras.</p>
                </div>
                <div class="col-3">
                    <h3>Segurança e Conformidade com a Lei:</h3>
                    <p>Garantimos a segurança e a conformidade com as leis de proteção de dados, armazenando informações de forma segura e confidencial. Isso proporciona tranquilidade aos pais e responsáveis, assegurando que suas informações estejam protegidas.</p>
                </div>
                <div class="col-3">
                    <h3>Aprimoramento na Relação com os Pais:</h3>
                    <p>Promovemos uma parceria mais sólida entre a escola e os pais, permitindo que estes estejam mais envolvidos na vida escolar de seus filhos. Compartilhamos informações sobre o desempenho acadêmico, eventos e atividades, fortalecendo a comunidade escolar.</p>
                </div>
                <div class="col-3">
                    <h3>Personalização e Escalabilidade:</h3>
                    <p>Nosso sistema é altamente personalizável, adaptando-se às necessidades específicas de cada escola. Além disso, é escalável, podendo crescer com a instituição, independentemente de seu tamanho ou complexidade.</p>
                </div>
            </div>

            <p>Ao adotar nosso Sistema de Gestão Escolar, sua instituição estará embarcando em uma jornada de transformação, marcada pela eficiência operacional, transparência e compromisso com a excelência educacional. Estamos aqui para impulsionar o seu sucesso e contribuir para um futuro educacional ainda mais promissor.</p>

        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'PlataformaPage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

