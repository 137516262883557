<template>
  <header>
    <div id="header-sticky"
      :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
      :'header__area header__transparent header__padding white-color '} ${header__white && header__white}`"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img height="80"  v-if="isSticky"
                  src="../../assets/img/logo_preto_fundo.jpg" alt="logo" />
                  
                  <img  height="80" v-else src="../../assets/img/logo_branco.png" alt="logo" />
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
            <div
              class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              "
            >
              <div :class="`main-menu ${header__white && 'main-menu-3'}`">
                <nav id="mobile-menu" class="d-none d-xl-block">
                  <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/quem-somos">Quem Somos</router-link></li>
                    <li><router-link to="/planos">Planos</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/contato">Contato</router-link></li>
                  </ul>
                </nav>
              </div>

              <div class="  ml-20 d-none d-sm-block ml-100">
                <a target="_blank" href="https://dev.plataforma.gestio.school/login?redirectFrom=%2F"  class=""><i class="fa fa-user"></i> &nbsp; Login</a>
              </div>
              <div class="header__btn ml-20 d-none d-sm-block">
                <router-link to="/planos" class="e-btn">Criar conta</router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

</template>

<script>
export default {
  name: "HomeHeader",
  props:{
    header__white:String,
  },
  data () {
    return {
      isSticky:false,
      showMiniCart:false,
      showSidebar:false,
      value:1,
      valueTwo:1,
      valueThree:1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    }
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleMiniCart () {
         this.showMiniCart = true;
       },
       handleCartClose () {
         this.showMiniCart = false;
       },
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
       handleIncreaseValue (value) {
         if(value === 'valueOne'){
           this.value++
         }
         if(value === 'valueTwo'){
           this.valueTwo++
         }
         if(value === 'valueThree'){
           this.valueThree++
         }
         
       },
       handleDecreaseValue (value) {
         if(value === 'valueOne' && this.value > 0){
           this.value--
         }
         if(value === 'valueTwo' && this.valueTwo > 0){
           this.valueTwo--
         }
         if(value === 'valueThree' && this.valueThree > 0){
           this.valueThree--
         }
       }
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
   },
};
</script>