<template>
  <EventDetailsTitle/>
   <section class="event__area pb-110">
    <div class="container">
        <div class="row">
            <div class="col-xxl-8 col-xl-8 col-lg-8">
                <div class="events__wrapper">
                <div class="events__thumb mb-35 w-img">
                    <img src="../../assets/img/events/event-1.jpg" alt="">
                </div>
                <div class="events__details mb-35">
                    <h3>Description</h3>
                    <p>He legged it up the kyver have it mush super me old mucker cheeky naff that are you taking the piss, blow off down the pub bite your arm off the wireless boot cor blimey guvnor happy days bender what a load of rubbish, say pardon me horse play spiffing Why car boot gosh bubble and squeak. Cheers Richard bugger show off show off pick your nose and blow off get stuffed mate chancer in my flat loo, bevvy amongst hunky-dory bender bubble and squeak me old mucker vagabond, barmy spend a penny chimney pot young delinquent bum bag the bee's knees chap, gosh nice one knees up the wireless Charles such a fibber. Mush barmy bleeding Jeffrey pardon me barney grub loo cup of tea bubble and squeak bugger all mate say, I bloke matie boy tickety-boo give us a bell up the duff bugger lurgy wind up I don't want no agro.</p>
                </div>
                <div class="events__allow mb-40">
                    <h3>This event will allow participants to:</h3>
                    <ul>
                        <li><i class="fal fa-check"></i> Business's managers, leaders</li>
                        <li><i class="fal fa-check"></i> Downloadable lectures, code and design assets for all projects</li>
                        <li><i class="fal fa-check"></i> Anyone who is finding a chance to get the promotion</li>
                    </ul>
                </div>
                <div class="events__tag">
                    <span><i class="fal fa-tag"></i></span>
                    <a href="#">Big data,  </a>
                    <a href="#">Data analysis,</a>
                    <a href="#">Data modeling</a>
                </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4">
                <div class="events__sidebar pl-70">
                <div class="events__sidebar-widget white-bg mb-20">
                    <div class="events__sidebar-shape">
                        <img class="events-sidebar-img-2" src="../../assets/img/events/event-shape-2.png" alt="">
                        <img class="events-sidebar-img-3" src="../../assets/img/events/event-shape-3.png" alt="">
                    </div>
                    <div class="events__info">
                        <div class="events__info-meta mb-25 d-flex align-items-center justify-content-between">
                            <div class="events__info-price">
                            <h5>$76.<span>00</span> </h5>
                            <h5 class="old-price">$142.00</h5>
                            </div>
                            <div class="events__info-discount">
                            <span>68% OFF</span>
                            </div>
                        </div>
                        <div class="events__info-content mb-35">
                            <ul>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>End: </span> July 26, 2022 12:30 am</h5>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>Time:</span>  10:45 AM-01:30 PM</h5>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>Venue: </span> New York, TX 82760, US</h5>
                                </div>
                            </li>
                            </ul>
                        </div>
                        <div class="events__join-btn">
                            <router-link to="/contact" class="e-btn e-btn-7 w-100">Enroll <i class="far fa-arrow-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="events__sidebar-widget white-bg">
                    <div class="events__sponsor">
                        <h3 class="events__sponsor-title">Sponsors</h3>
                        <div class="events__sponsor-thumb mb-35">
                            <img src="../../assets/img/events/sponsor-logo.png" alt="">
                        </div>
                        <div class="events__sponsor-info">
                            <h3>Thomas R. Toe</h3>
                            <h4>Email: <span>support@educal.com</span></h4>
                            <div class="events__social d-xl-flex align-items-center">
                            <h4>Share:</h4>
                            <ul>
                                <li><a href="#" class="fb" >
                                    <i class="fab fa-facebook-f"></i>
                                </a></li>
                                <li><a href="#" class="tw" >
                                    <i class="fab fa-twitter"></i>
                                </a></li>
                                <li><a href="#" class="pin" >
                                    <i class="fab fa-pinterest-p"></i>
                                </a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import EventDetailsTitle from './EventDetailsTitle.vue';

export default {
    name:'EventDetailsArea',
    components:{
        EventDetailsTitle,
    }
}
</script>

