<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img height="100" :src="`${footerBgTwo ? require('../../assets/img/logo_branco.png') : require('../../assets/img/logo_branco.png')}`" alt="">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
<!--                        <p>-->
<!--                          Sistema especializado em gestão escolar, com foco em escolas e faculdades de pequeno, médio e grande porte.-->
<!--                        </p>-->

                        <div class="footer__social">
                           <ul>
                              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#" class="pin"><i class="fab fa-pinterest-p"></i></a></li>
                           </ul>
                        </div>




                     </div>
                  </div>
               </div>

               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Produto</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/secretaria">Secretaria</a></li>
                              <li><a href="/pedagogico">Pedagógico</a></li>
                              <li><a href="/plataforma">Plataforma</a></li>
                              <li><a href="/escolas">Escolas</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Aprenda</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/videos">Vídeos</a></li>
                              <li><a href="/tutoriais">Tutoriais</a></li>
                              <li><a href="/api">API</a></li>
                              <li><a href="/blog">Blog</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

              <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                <div class="footer__widget mb-50">
                  <div class="footer__widget-head mb-22">
                    <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Empresa</h3>
                  </div>
                  <div class="footer__widget-body">
                    <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/quem-somos">Quem somos</a></li>
                        <li><a href="/planos">Planos</a></li>
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="/contato">Contato</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                <div class="footer__widget mb-50">
                  <div class="footer__widget-head mb-22">
                    <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Util</h3>
                  </div>
                  <div class="footer__widget-body">
                    <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                      <ul>
                        <li><a href="/ajuda">Ajuda</a></li>
                        <li><a href="/suporte">Suporte</a></li>
                        <li><a href="/termos">Termos</a></li>
                        <li><a href="/politica-de-privacidade">Politica de privacidade</a></li>
                        <li><a href="/sitemap">Sitemap</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                <div class="footer__widget mb-50">
                  <div class="footer__widget-head mb-22">
                    <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Contato</h3>
                  </div>
                  <div class="footer__widget-body">
                    <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                      <ul>
                        <li><a href="#">contato@gestio.school</a></li>
                        <li><a href="#">(11) 00000-0000</a></li>
                        <li><br></li>
                        <li><strong class="white-color">OUVIDORIA</strong></li>
                        <li><a href="#">ouvidoroa@gestio.school</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>© 2023 - Gestio School</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
   }
};
</script>
