<script>
export default {
  props: {
    qtd: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: 'Plano 1'
    },
    showButton: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<template>
  <h3>{{ title }}</h3>
  <p class="black-color" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non tortor et leo viverra mattis nec id mi. Maecenas mollis sapien sapien, quis finibus diam egestas viverra.</p>
  <div class="your-order-table table-responsive">
    <table>
      <thead>
      <tr>
        <th class="product-name"></th>
        <th class="product-total">Qtd</th>
      </tr>
      </thead>
      <tbody>
      <tr class="cart_item">
        <td class="product-name">
          Quantidade de usuários
        </td>
        <td class="product-total">
          <span class="amount">{{ qtd * 10 }}</span>
        </td>
      </tr>
      <tr class="cart_item">
        <td class="product-name">
          Espaço em disco
        </td>
        <td class="product-total">
          <span class="amount">{{ qtd * 100 }}GB</span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr class="cart-subtotal">
        <th>
          Escolas/filiais
        </th>
        <td><span class="amount">{{ qtd * 10 }}</span></td>
      </tr>
      <tr class="order-total">
        <th>
          Preço
        </th>
        <td>
          <strong><span class="amount">R$ {{ qtd * 100 }},00</span></strong>
        </td>
      </tr>
      </tfoot>
    </table>
    <div class="order-button-payment mt-20" v-if="showButton">
      <a  href="/carrinho" class="e-btn e-btn-border">Assinar plano</a>
    </div>
  </div>
</template>

<style scoped>

</style>