<template>
<section class="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
   <div class="hero__shape">
      <img class="hero-1-circle" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="../../assets/img/shape/hero/hero-1-circle-2.png" alt="">
      <img class="hero-1-dot-2" src="../../assets/img/shape/hero/hero-1-dot-2.png" alt="">
   </div>
   <div class="container">
      <div class="hero__content-wrapper mt-90">
         <div class="row align-items-center">
            <div class="col-xxl-9 col-xl-8 col-lg-8 col-md-8">
               <div class="hero__content p-relative z-index-1">
                  <h3 class="hero__title white-color">
                     <span>O melhor sistema de  </span>
                     <span class="yellow-shape">gestão <img src="../../assets/img/shape/yellow-bg.png" alt="yellow-shape"> </span>
                    escolar!</h3>
                     <p></p>
                     <router-link to="/planos" class="e-btn">Veja nossos planos!</router-link>
               </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                  <form class="card">

                    <div class="card-body">
                      <br>
                      <h3>Agende uma apresentação!</h3>
                      <br>
                      <p>Demonstração sem compromisso, venha conversar um pouco com a gente.</p>
                      <br>


                      <div class="input-group">
                        <input type="text" placeholder="Nome completo" class="form-control">
                      </div>
                      <div class="input-group mt-10">
                        <input type="text" placeholder="E-mail" class="form-control">
                      </div>
                      <div class=" mt-10">
                        <div class="row">
                          <div class="col-3"><input type="text" placeholder="DDD" class="form-control"></div>
                          <div class="col-9"><input type="text" placeholder="Telefone" class="form-control"></div>
                        </div>
                      </div>
                      <div class="input-group mt-10">
                        <input type="text" placeholder="Assunto" class="form-control">
                      </div>

                      <div class="input-group mt-10">
                        <textarea placeholder="Mensagem" class="form-control mh-100"></textarea>
                      </div>

                      <button class=" mt-10 btn btn-secondary ">Enviar</button>

                      <br>
                      <br>
                    </div>


                  </form>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>