<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Suporte" />
  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">

            <p>Bem-vindo ao Suporte do Sistema de Gestão Escolar Moderno!</p>
            <p>Estamos dedicados a fornecer a você, usuário do nosso Sistema de Gestão Escolar, a melhor experiência possível. Nossa equipe de suporte está aqui para ajudar e garantir que você obtenha o máximo valor de nossa plataforma inovadora.</p>
            <h3>Como Podemos Ajudar?</h3>
            <p>1. Central de Ajuda:</p>
            <p>Nossa Central de Ajuda é uma fonte abrangente de informações, tutoriais e guias que abordam uma ampla gama de tópicos relacionados à nossa plataforma. Você pode encontrar respostas para muitas perguntas comuns aqui.</p>
            <p>2. Contate-nos:</p>
            <p>Se você precisar de suporte personalizado ou não encontrar a resposta que procura em nossa Central de Ajuda, nossa equipe de suporte está pronta para ajudar. Você pode nos contatar através do formulário de suporte nesta página.</p>
            <p>3. Atualizações e Novidades:</p>
            <p>Fique por dentro das últimas atualizações, melhorias e recursos adicionados ao nosso Sistema de Gestão Escolar. Mantenha-se informado sobre as inovações que tornam nossa plataforma ainda mais eficiente e fácil de usar.</p>
            <p>4. Perguntas Frequentes:</p>
            <p>Reunimos uma lista de perguntas frequentes para ajudá-lo a encontrar informações de maneira rápida e fácil. Se você tem uma pergunta comum, é provável que encontre a resposta aqui.</p>
            <h3>Dicas para um Melhor Suporte:</h3>
            <p>Forneça Detalhes:</p>
            <p>Ao entrar em contato conosco, forneça informações detalhadas sobre sua consulta ou problema. Quanto mais informações tivermos, mais precisamente podemos ajudar.</p>
            <p>Capturas de Tela:</p>
            <p>Se possível, envie capturas de tela que ilustrem o problema que está enfrentando. Isso nos ajudará a entender melhor a situação.</p>
            <p>Feedback Construtivo:</p>
            <p>Estamos sempre buscando melhorar nossa plataforma. Se tiver sugestões ou feedback, ficaremos felizes em ouvi-los para aprimorar ainda mais nossa solução.</p>
            <p>Estamos aqui para apoiá-lo em cada etapa do seu uso do Sistema de Gestão Escolar Moderno. Nosso compromisso é garantir que você alcance o máximo de produtividade e eficiência em suas operações escolares. Agradecemos por escolher nossa plataforma e estamos ansiosos para ajudá-lo!</p>
            <p>Equipe de Suporte do Sistema de Gestão Escolar Moderno</p>


        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'SuportePage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

