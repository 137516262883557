export default {
    data() {
        return {
           blogItems:  [
               {
                   id: 1,
                   blogImg: require(`@/assets/img/blog/blog-1.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'A Magia da Leitura na Infância: Desperte o Mundo dos Livros para Seus Filhos',
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: 'Gestio School',
                   category: 'Livros',
                   date:'Abril 02, 2022',
                   color:'green'
               },
               {
                   id: 2,
                   blogImg: require(`@/assets/img/blog/blog-2.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'A Importância da Socialização no Ambiente Escolar: Construindo Relações e Aprendizados para a Vida',
                   authorImg: require(`@/assets/img/course/teacher/teacher-2.jpg`),
                   authorName: 'Gestio School',
                   category: 'Escola',
                   date:'Janeiro 05, 2022',
                   color:'green'
               },
               {
                   id: 3,
                   blogImg: require(`@/assets/img/blog/blog-3.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'Criando um Ambiente Escolar Inspirador: Nutrindo o Crescimento e a Imaginação das Crianças\n',
                   authorImg: require(`@/assets/img/course/teacher/teacher-3.jpg`),
                   authorName: 'Gestio School',
                   category: 'Escola',
                   date:'Fevereiro 08, 2022',
                   color:'green',
               },
               {
                   id: 4,
                   blogImg: require(`@/assets/img/blog/blog-4.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'A Importância do Representante de Turma: Fortalecendo a Voz dos Estudantes e Construindo Comunidades Escolares Vibrantes\n',
                   authorImg: require(`@/assets/img/course/teacher/teacher-4.jpg`),
                   authorName: 'Gestio School',
                   category: 'Escola',
                   date:'Março 01, 2022',
                   color:'green'
               }
           ]
        }
    }
}