<template>
  <section class="checkout-area pb-70">
    <br>
    <br>
    <div class="container">
      <form action="#">
        <div class="row">

          <div class="col-lg-9">
            <div class="col-lg-12 passos">
              <div class="row">
                <div class="col"><span :class=" step==1 ? 'active':'' ">1</span></div>
                <div class="col"><span :class=" step==2 ? 'active':'' ">2</span></div>
                <div class="col"><span :class=" step==3 ? 'active':'' ">3</span></div>
                <div class="col"><span :class=" step==4 ? 'active':'' ">4</span></div>
              </div>
            </div>
            <div v-if="step==1" class="checkbox-form">
              <h3>Dados pessoais</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="checkout-form-list">
                    <label>Primeiro nome <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="checkout-form-list">
                    <label>Sobrenome <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Documento <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Data de nascimento <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="country-select">
                    <label>Sexo <span class="required">*</span></label>
                    <select>
                      <option value="1">Masculino</option>
                      <option value="2">Feminino</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>E-mail <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Senha <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Confirmação de senha <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>


              </div>
            </div>
            <div v-if="step==2" class="checkbox-form">

              <div class="info-step">

                <p>
                  <span>Dados pessoais</span>

                  Gabriel Alencar, 30 anos, Documento: 000.000.000-00, Masculino, E-mail: gabriel@teste.com.br

                </p>
              </div>

              <h3>Dados da escola</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>CNPJ <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="checkout-form-list">
                    <label>Razão <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Fantasia <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Data de abertura <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>E-mail <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="checkout-form-list">
                    <label>CEP <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="checkout-form-list">
                    <label>Endereço <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="checkout-form-list">
                    <label>Número <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="checkout-form-list">
                    <label>Complemento <span class="required">*</span></label>
                    <input type="text" placeholder=""/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="country-select">
                    <label>Estado <span class="required">*</span></label>
                    <select>
                      <option value="1">SP</option>
                      <option value="2">RJ</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="country-select">
                    <label>Cidade <span class="required">*</span></label>
                    <select>
                      <option value="1">São Paulo</option>
                      <option value="2">Rio de Janeiro</option>
                    </select>
                  </div>
                </div>


              </div>
            </div>
            <div v-if="step==3" class="checkbox-form">

              <div class="info-step">
                <p>
                  <span>Dados pessoais</span>
                  Gabriel Alencar, 30 anos, Documento: 000.000.000-00, Masculino, E-mail: gabriel@teste.com.br
                </p>
              </div>
              <div class="info-step">
                <p>
                  <span>Dados da escola</span>
                  CNPJ: 00.000.000/0000-00, Razão: Teste, Fantasia: Teste, Data de abertura: 01/01/2021, E-mail: escola@email.com.br
                </p>
              </div>

              <h3>Dados da pagamento</h3>
              <div class="row">


                <div class="col-12">
                  <br>
                  <br>
                  <br>
                  <div class='card-wrapper'></div>
                  <br>
                  <br>
                  <br>
                </div>

                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Número do cartão <span class="required">*</span></label>
                    <input type="text" name="number" class="number"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="checkout-form-list">
                    <label>Nome (igual ao cartão) <span class="required">*</span></label>
                    <input type="text" name="name" class="name"/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="checkout-form-list">
                    <label>Data de expiração <span class="required">*</span></label>
                    <input type="text" name="expiry" class="expiry"/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="checkout-form-list">
                    <label>Código de segurança <span class="required">*</span></label>
                    <input type="text" name="cvc" class="cvc"/>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="checkout-form-list">
                    <label>Documento do títular do cartão <span class="required">*</span></label>
                    <input type="text" name="cod" class="cod"/>
                  </div>
                </div>

              </div>
            </div>
            <div v-if="step==4" class="checkbox-form">


              <h3>Data: {{ getData() }}</h3>

              <h1>Código de pedido: #32144r43</h1>

              <h2><strong>Status:</strong> <span class="text-success">Pago</span></h2>

              <br>

              <h4>
                <strong>Plano adquirido:</strong> Plano 1
              </h4>
              <br>
              <p>
                Verifique seu e-mail para mais informações.
              </p>
              <br>



              <div class="info-step">
                <p>
                  <span>Dados pessoais</span>
                  Gabriel Alencar, 30 anos, Documento: 000.000.000-00, Masculino, E-mail: gabriel@teste.com.br
                </p>
              </div>
              <div class="info-step">
                <p>
                  <span>Dados da escola</span>
                  CNPJ: 00.000.000/0000-00, Razão: Teste, Fantasia: Teste, Data de abertura: 01/01/2021, E-mail: escola@email.com.br
                </p>
              </div>
              <div class="info-step">
                <p>
                  <span>Pagamento</span>
                  Número do cartão: 0000 **** **** 0000, Nome: Gabriel Alencar, Data de expiração: 01/01, Documento: 000.000.000-00
                </p>
              </div>




            </div>

            <div class="col-md-12" v-if="step<=3">

              <div class="d-grid gap-2">

                <button type="button" @click="stepUp" v-if="step<=2" class="btn btn-primary ">Continuar</button>
                <button type="button" @click="stepUp" v-if="step>2" class="btn btn-success ">Finalizar pagamento</button>

              </div>
            </div>

          </div>
          <div class="col-3">
            <div class="your-order">
              <TablePlano title="Plano 1" :show-button="false"/>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import TablePlano from "@/components/TablePlano.vue";
import * as Card from "card";
import moment from "moment";

export default {
  name: 'CheckoutArea',
  components: {
    TablePlano
  },
  data() {
    return {
      showAcc: false,
      shipBox: false,
      step: 1
    }
  },
  mounted() {



  },
  methods: {
    showCard(){
      try{


        setTimeout(function (){
          new Card({
            form: "form",
            container: ".card-wrapper",
            formSelectors: {
              numberInput: ".number",
              expiryInput: ".expiry",
              cvcInput: ".cvc",
              nameInput: ".name"
            },
            width: 500,
            formatting: true,
            placeholders: {
              number: "•••• •••• •••• ••••",
              name: "Nome Completo",
              expiry: "••/••",
              cvc: "•••"
            }
          });
        },800)

      }catch (e){
        console.log(e)
      }
    },
    getData(){
      return moment().format('DD/MM/YYYY')
    },
    showCreateAcc() {
      return this.showAcc = !this.showAcc
    },
    handleShipBox() {
      return this.shipBox = !this.shipBox
    },
    stepUp() {
      this.step++

      if(this.step==3){
        this.showCard()
      }
    },
  }
};
</script>
