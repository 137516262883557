<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Secretaria" />

  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">


            <p>Bem-vindo ao futuro da administração educacional com nosso revolucionário Sistema de Gestão Escolar. Projetado para otimizar e acelerar os processos da secretaria da escola, nossa plataforma oferece uma gama de vantagens que transformarão a maneira como as escolas operam e se relacionam com alunos, pais e professores.</p>
            <div class="row">
                <div class="col-3">
                    <h3>Automatização de Tarefas Repetitivas:</h3>
                    <p>Nosso sistema inteligente automatiza tarefas rotineiras da secretaria, como matrículas, geração de boletins e relatórios, agendamento de reuniões e controle de presença. Isso libera tempo para que a equipe da secretaria se concentre em atividades mais estratégicas.</p>
                </div>
                <div class="col-3">
                    <h3>Acesso Simplificado às Informações:</h3>
                    <p>Todas as informações essenciais estão organizadas de forma intuitiva e fácil de acessar. Professores, alunos e pais podem verificar notas, horários, comunicados e calendários acadêmicos em tempo real, por meio de uma interface amigável e personalizada.</p>
                </div>
                <div class="col-3">
                    <h3>Comunicação Instantânea:</h3>
                    <p>Facilitamos a comunicação entre a escola, os pais e os alunos por meio de mensagens instantâneas e notificações em tempo real. Alertas sobre atividades escolares, eventos ou comunicados importantes mantêm todos informados de maneira eficaz.</p>
                </div>
                <div class="col-3">
                    <h3>Controle de Finanças e Pagamentos Simplificado:</h3>
                    <p>Nosso sistema permite o gerenciamento eficiente das finanças escolares, incluindo controle de mensalidades, pagamentos e cobranças. Os pais podem efetuar pagamentos online de forma segura e fácil, agilizando o processo e reduzindo o tempo de inadimplência.</p>
                </div>
                <div class="col-3">
                    <h3>Gestão de Documentação Digital:</h3>
                    <p>Adeus à papelada! Nosso sistema armazena e gerencia documentos de forma segura e em conformidade com as leis de proteção de dados. Isso elimina a necessidade de arquivos físicos e agiliza o acesso a documentos importantes quando necessário.</p>
                </div>
                <div class="col-3">
                    <h3>Tomada de Decisões Baseada em Dados:</h3>
                    <p>Com um robusto conjunto de ferramentas de análise e relatórios, fornecemos insights valiosos sobre o desempenho acadêmico dos alunos e o funcionamento da escola. Essas informações são fundamentais para aprimorar estratégias educacionais e tomar decisões informadas.</p>
                </div>
                <div class="col-3">
                    <h3>Customização e Escalabilidade:</h3>
                    <p>Nosso sistema é altamente flexível e pode ser adaptado de acordo com as necessidades específicas de cada escola. À medida que a instituição cresce, o sistema acompanha esse crescimento, garantindo uma solução escalável e eficaz.</p>
                </div>
            </div>
            <p>A implementação do nosso Sistema de Gestão Escolar revolucionará a experiência acadêmica, promovendo eficiência, transparência e melhor comunicação entre todos os envolvidos na jornada educacional. Junte-se a nós e abrace o futuro da gestão escolar moderna!</p>

        </div>
      </div>
    </div>
  </section>

  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
  name:'SecretariaPage',
  components:{
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

