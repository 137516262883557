<template>
    <section class="why__area pt-125">
      <div class="container">
         <div class="row align-items-center">
            <div class="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
               <div class="why__content pr-50 mt-40">
                  <div class="section__title-wrapper mb-30">
                     <span class="section__sub-title">Why Choses Me</span>
                     <h2 class="section__title">Tools for <span class="yellow-bg yellow-bg-big">Teachers<img src="../../assets/img/shape/yellow-bg.png" alt=""></span> and Learners</h2>
                     <p>Oxford chimney pot Eaton faff about blower blatant brilliant, bubble and squeak he legged it Charles bonnet arse at public school bamboozled.</p>
                  </div>
                  <div class="why__btn">
                     <router-link to="/contact" class="e-btn e-btn-3 mr-30">Join for Free</router-link>
                     <router-link to="/about" class="link-btn">
                        Learn More 
                        <i class="far fa-arrow-right"></i>
                        <i class="far fa-arrow-right"></i>
                     </router-link>
                  </div>
               </div>
            </div>
            <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
               <div class="why__thumb">
                  <img src="../../assets/img/why/why.png" alt="">
                  <img class="why-green" src="../../assets/img/why/why-shape-green.png" alt="">
                  <img class="why-pink" src="../../assets/img/why/why-shape-pink.png" alt="">
                  <img class="why-dot" src="../../assets/img/why/why-shape-dot.png" alt="">
                  <img class="why-line" src="../../assets/img/why/why-shape-line.png" alt="">
               </div>
            </div>
         </div>
      </div>
   </section>
   </template>
   
   <script>
   export default {
      name:'WhyArea'
   };
   </script>