<template>
  <Header header__white="header__white" />
  
  <section class="page__title-area page__title-height-2 page__title-overlay d-flex align-items-center" :style="{background:'url('+require('../assets/img/page-title/page-title-3.jpg')+')',backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}">
   <div class="page__title-shape">
      <img class="page-title-shape-1" src="../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-2" src="../assets/img/page-title/page-title-shape-2.png" alt="">
      <img class="page-title-shape-3" src="../assets/img/page-title/page-title-shape-3.png" alt="">
      <img class="page-title-shape-4" src="../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-xxl-10 col-xl-10 col-lg-10 text-center ">
            <div class="page__title-wrapper mt-110">
               <span class="page__title-pre">{{blog.category}}</span>
               <h3 class="page__title-2">{{blog.title}}</h3> 

            </div>
         </div>
      </div>
   </div>
</section>

   <section class="blog__area pt-120 pb-120">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-xxl-8 col-xl-8 col-lg-8">
               <div class="blog__wrapper">
                  <div class="blog__text mb-40" v-if="id==1">

                      <p>A leitura é uma porta mágica que abre os horizontes da imaginação e proporciona uma jornada incrível através das palavras. Para as crianças, essa experiência vai além de um simples ato: é um mergulho em universos encantados que estimulam o pensamento criativo, o desenvolvimento cognitivo e o crescimento emocional.</p>
                      <h3>1. Desenvolvimento da Imaginação e Criatividade:</h3>
                      <p>A leitura é uma fonte inesgotável de estímulos para a imaginação das crianças. Cada história é como um convite para um novo mundo, onde elas podem visualizar personagens, cenários e aventuras. Através da leitura, os pequenos criam imagens mentais, desenvolvendo a criatividade de forma única.</p>
                      <h3>2. Aquisição de Vocabulário e Habilidades Linguísticas:</h3>
                      <p>Ler amplia o vocabulário e enriquece a linguagem das crianças. A exposição a diferentes palavras, estruturas e contextos favorece o desenvolvimento das habilidades de comunicação e expressão. Além disso, crianças que leem regularmente tendem a ter um desempenho melhor na escola.</p>
                      <h3>3. Empatia e Compreensão do Mundo:</h3>
                      <p>Ao se colocarem no lugar dos personagens dos livros, as crianças aprendem sobre as emoções, desafios e experiências humanas. Isso fortalece a empatia e a compreensão, permitindo que elas se conectem de maneira mais significativa com o mundo ao seu redor.</p>
                      <h3>4. Momentos de Afeto e Conexão:</h3>
                      <p>Compartilhar histórias cria laços afetivos entre pais, cuidadores e crianças. Os momentos dedicados à leitura em família são preciosos e estabelecem memórias duradouras. Esse contato com os livros promove um ambiente acolhedor, repleto de amor e aprendizado.</p>
                      <h3>5. Desperte o Amor Pela Leitura desde Cedo:</h3>
                      <p>Comece cedo a introdução à leitura na vida das crianças. Ofereça livros adequados à idade e interesse delas, crie um espaço aconchegante para a leitura e faça disso um ritual diário. Mostre o prazer que a leitura proporciona e encoraje a curiosidade pelo mundo dos livros.</p>
                      <p><strong>Conclusão:</strong> Investir na Leitura é Investir no Futuro!</p>
                      <p>A leitura é um poderoso instrumento de crescimento e desenvolvimento para as crianças. Estimular o hábito de ler desde cedo é um investimento valioso, proporcionando benefícios que perdurarão ao longo de toda a vida. Abra as páginas dos livros e leve seus filhos para uma jornada inesquecível de aprendizado e imaginação!</p>
                      <p>Descubra a magia da leitura e ajude seus filhos a voarem nas asas da imaginação!</p>

                  </div>
                  <div class="blog__text mb-40" v-if="id==2">
                      <p>A escola não é apenas um local de aprendizado acadêmico; é também um ambiente rico para o desenvolvimento social e emocional das crianças. A interação com colegas, professores e demais membros da comunidade escolar é fundamental para a formação de habilidades essenciais que irão moldar o futuro dos estudantes.</p>
                      <h3>1. Aprendizado Social Além dos Livros:</h3>
                      <p>A interação com os colegas proporciona lições valiosas que não podem ser encontradas nos livros didáticos. A troca de experiências e pontos de vista enriquece a compreensão das diferentes realidades e culturas, preparando os jovens para a diversidade do mundo real.</p>
                      <h3>2. Desenvolvimento de Habilidades Sociais:</h3>
                      <p>O convívio diário na escola oferece oportunidades para aprender a trabalhar em equipe, resolver conflitos, expressar opiniões e praticar a empatia. Essas habilidades são cruciais para a vida pessoal e profissional, moldando líderes e cidadãos responsáveis.</p>
                      <h3>3. Estímulo à Comunicação Eficaz:</h3>
                      <p>A socialização na escola incentiva a comunicação, seja ela verbal ou não verbal. Aprender a se expressar de forma clara, ouvir ativamente e compreender diferentes formas de comunicação é essencial para estabelecer relacionamentos saudáveis ao longo da vida.</p>
                      <h3>4. Construção de Amizades e Redes de Apoio:</h3>
                      <p>A escola é um terreno fértil para o cultivo de amizades verdadeiras e duradouras. Essas relações fornecem apoio emocional, compartilhamento de experiências e crescimento pessoal. Ter uma rede de amigos sólida pode fazer uma grande diferença na jornada de vida de qualquer indivíduo.</p>
                      <h3>5. Preparação para o Mundo Profissional:</h3>
                      <p>A socialização na escola espelha, de certa forma, as interações que os estudantes terão no ambiente de trabalho. A capacidade de colaborar, negociar, liderar e se comunicar efetivamente são habilidades altamente valorizadas no mundo profissional.</p>
                      <p><strong>Conclusão:</strong> Fomentando a Socialização para um Futuro Promissor!</p>
                      <p>A socialização no ambiente escolar é um investimento no futuro dos estudantes. Além de enriquecer a experiência educacional, ela contribui para a formação de indivíduos mais confiantes, empáticos e preparados para enfrentar os desafios da vida. Valorizar e promover essa interação é essencial para uma educação completa e abrangente.</p>
                      <p>Encoraje a socialização nas escolas e construa um futuro baseado na compreensão, respeito e colaboração mútua!</p>
                  </div>
                  <div class="blog__text mb-40" v-if="id==3">
                      <p>O ambiente escolar desempenha um papel crucial no desenvolvimento das crianças. É nesse espaço que elas aprendem, crescem e se inspiram para um futuro brilhante. Criar um ambiente escolar inspirador é uma missão compartilhada por educadores, pais e todos aqueles que desejam ver as crianças florescerem de maneira positiva e criativa.</p>
                      <h3>1. Estimule a Curiosidade e a Descoberta:</h3>
                      <p>Fomente a curiosidade natural das crianças, criando um ambiente rico em estímulos visuais, táteis e cognitivos. Ofereça materiais diversos, livros, jogos e atividades que as incentivem a explorar, questionar e aprender de maneira lúdica.</p>
                      <h3>2. Crie Espaços Versáteis e Acolhedores:</h3>
                      <p>Desenvolva ambientes flexíveis e acolhedores, que possam ser adaptados conforme as necessidades. Espaços com cores vivas, mobiliário confortável e áreas de recreação podem inspirar criatividade e promover um senso de pertencimento e bem-estar.</p>
                      <h3>3. Incorpore Elementos da Natureza:</h3>
                      <p>Integre a natureza ao ambiente escolar, seja através de jardins, plantas ou decorações inspiradas na fauna e flora. A conexão com a natureza estimula a paz interior, a criatividade e a consciência ambiental nas crianças.</p>
                      <h3>4. Promova a Arte e a Expressão Criativa:</h3>
                      <p>Ofereça oportunidades para que as crianças expressem sua criatividade através da arte. Disponibilize materiais artísticos variados e estimule projetos criativos que permitam que cada criança mostre seu talento único.</p>
                      <h3>5. Estimule a Colaboração e a Interação:</h3>
                      <p>Crie atividades colaborativas que incentivem a cooperação e a troca de ideias entre os estudantes. Projetos em equipe proporcionam um ambiente de aprendizado dinâmico e inspirador.</p>
                      <h3>6. Celebre as Conquistas e o Esforço:</h3>
                      <p>Reconheça e celebre o progresso e as conquistas das crianças, sejam acadêmicas ou pessoais. Isso gera um senso de realização e motivação, incentivando-as a continuar se esforçando e aprendendo.</p>
                      <p><strong>Conclusão:</strong> Cultivando Espaços de Aprendizado Inspiradores</p>
                      <p>Manter um ambiente escolar inspirador é fundamental para nutrir a criatividade, a confiança e o amor pelo aprendizado nas crianças. Ao incorporar elementos que estimulem a curiosidade, promovam a colaboração e celebrem a individualidade de cada criança, estamos contribuindo para a formação de mentes brilhantes e sonhadoras.</p>
                      <p>Que cada cantinho da escola seja um convite para a imaginação, a descoberta e a felicidade de aprender!</p>
                  </div>
                  <div class="blog__text mb-40" v-if="id==4">
                      <p>O representante de turma desempenha um papel fundamental no contexto escolar, atuando como um elo essencial entre os estudantes e a equipe pedagógica. Essa figura não apenas representa a classe, mas também é um agente de mudança e de construção de uma comunidade escolar mais unida e participativa.</p>
                      <h3>1. Voz dos Estudantes Amplificada:</h3>
                      <p>O representante de turma é a voz dos seus colegas. Ele ouve as preocupações, sugestões e necessidades dos estudantes e as apresenta de forma organizada e construtiva para os professores e direção da escola. Isso promove um diálogo aberto e eficaz, permitindo que as demandas dos estudantes sejam atendidas de maneira mais adequada.</p>
                      <h3>2. Fomento da Participação Ativa:</h3>
                      <p>Ao incentivar a participação ativa dos estudantes na vida escolar, o representante de turma contribui para criar um ambiente de aprendizado mais dinâmico e envolvente. A participação ativa gera um sentimento de pertencimento e motivação, levando os estudantes a se interessarem mais pelas atividades e decisões da escola.</p>
                      <h3>3. Mediação e Resolução de Conflitos:</h3>
                      <p>O representante de turma desempenha um papel de mediador, auxiliando na resolução de conflitos entre colegas. Essa habilidade de gerenciar desentendimentos de forma pacífica é valiosa para a promoção da paz e da harmonia no ambiente escolar.</p>
                      <h3>4. Responsabilidade e Liderança:</h3>
                      <p>Assumir a função de representante de turma ensina os estudantes sobre responsabilidade, liderança e tomada de decisões. Eles aprendem a equilibrar as expectativas de seus colegas com as regras e objetivos da escola, desenvolvendo habilidades essenciais para o seu crescimento pessoal e profissional.</p>
                      <h3>5. Estímulo à Colaboração:</h3>
                      <p>O representante de turma promove a colaboração e a coesão dentro da turma, incentivando todos a trabalharem juntos para atingir metas comuns. Essa colaboração não apenas beneficia a turma, mas também prepara os estudantes para uma vida em sociedade, onde a colaboração é essencial.</p>
                      <p><strong>Conclusão:</strong> O Representante de Turma como Agente de Transformação Escolar</p>
                      <p>O papel do representante de turma é significativo na promoção de uma educação mais democrática, participativa e centrada nos estudantes. Ao apoiar a voz dos estudantes, fomentar a participação e incentivar a colaboração, ele contribui para a construção de um ambiente escolar mais inclusivo e empoderado.</p>
                      <p>Valorize o representante de turma como peça-chave na construção de uma educação mais próxima, participativa e centrada nos estudantes!</p>
                  </div>

                  <div class="blog__line"></div>


                  <div class="blog__recent mb-65">
                     <div class="section__title-wrapper mb-40">
                        <h2 class="section__title">Relacionados <span class="yellow-bg-sm"> <img src="../assets/img/shape/yellow-bg-4.png" alt="">  </span></h2>

                     </div>
                     <div class="row">
                        <div v-for="blog in blogItems.slice(0,2)" :key="blog.id" class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="blog__item white-bg mb-30 transition-3 fix">
                              <div class="blog__thumb w-img fix">
                                 <router-link :to="`/blog-details/${blog.id}`">
                                       <img :src="blog.blogImg" alt="">
                                 </router-link>
                              </div>
                              <div class="blog__content">
                                 <div class="blog__tag">
                                    <a href="#" :class="blog.color">{{blog.category}}</a>
                                 </div>
                                 <h3 class="blog__title">
                                     <router-link :to="`/blog-details/${blog.id}`">
                                     {{blog.title}}</router-link>
                                 </h3>
                                 <div class="blog__meta d-flex align-items-center justify-content-between">
                                    <div class="blog__author d-flex align-items-center">
                                       <div class="blog__author-thumb mr-10">
                                          <img :src="blog.authorImg" alt="">
                                       </div>
                                       <div class="blog__author-info">
                                          <h5>{{blog.authorName}}</h5>
                                       </div>
                                    </div>
                                    <div class="blog__date d-flex align-items-center">
                                       <i class="fal fa-clock"></i>
                                       <span>{{blog.date}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>

         </div>
      </div>
    </section>
  <Footer footerPadding="true" />
</template>

<script>
import BlogItemMixin from '../mixins/blogMixin';
import Header from '../components/Home/Header.vue';
import Footer from '../components/Home/Footer.vue';

export default {
  name: "BlogDetails",
  mixins: [BlogItemMixin],
  components:{
     Header,
     Footer,
  },
    data() {
        return {
            id: this.$route.params.id,
            blog: {}
        }
    },
    methods: {
        getBlog(blogId) {
            this.blog = this.blogItems.find(item => item.id == blogId);
        }
    },
    created() {
        this.getBlog(this.id);
    }
};
</script>
