<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <Breadcrumb title="Quem Somos" />
  <section class="cart-area pt-100 pb-100 interna">
    <div class="container">
      <div class="row">
        <div class="col-12">



            <p>Bem-vindo à Gestio Scholl, a vanguarda da inovação em Gestão Escolar! Estamos empenhados em revolucionar a forma como as instituições educacionais gerenciam suas operações, promovendo a excelência no ensino e aprendizagem.</p>
            <h3>Nossa Missão</h3>
            <p>Nosso propósito é capacitar escolas a atingirem todo o seu potencial, oferecendo soluções tecnológicas inovadoras. Buscamos facilitar a administração escolar, possibilitar a comunicação eficaz entre os envolvidos e promover um ambiente educacional enriquecedor.</p>
            <h3>Nossa Visão</h3>
            <p>Visualizamos um futuro em que cada instituição educacional tenha acesso a ferramentas intuitivas e eficientes para otimizar processos, potencializar o desenvolvimento dos alunos e promover a transparência entre escola, pais e comunidade.</p>
            <h3>Nossos Valores</h3>
            <h4>1. Inovação:</h4>
            <p>Acreditamos na constante evolução e na busca por soluções pioneiras que impulsionem a transformação do cenário educacional.</p>
            <h4>2. Excelência:</h4>
            <p>Nosso compromisso é fornecer produtos e serviços de alta qualidade que superem as expectativas de nossos clientes e parceiros.</p>
            <h4>3. Colaboração:</h4>
            <p>Valorizamos a colaboração e a cooperação, tanto internamente quanto com nossos clientes, para alcançar objetivos comuns e fomentar o aprendizado contínuo.</p>
            <h4>4. Transparência:</h4>
            <p>Praticamos a transparência em todas as interações, mantendo um relacionamento aberto e honesto com nossos clientes, colaboradores e demais partes interessadas.</p>
            <h4>5. Foco no Cliente:</h4>
            <p>Colocamos as necessidades e experiências dos nossos clientes no centro de tudo o que fazemos, buscando superar suas expectativas e garantir sua satisfação.</p>
            <h2>Nossa História</h2>
            <p>Desde 2023, temos trabalhado incansavelmente para desenvolver soluções de gestão escolar que facilitem o cotidiano das instituições de ensino. Nossa trajetória é marcada pela dedicação em fornecer tecnologia avançada e suporte excepcional.
                Nossa Equipe</p>
            <p>Contamos com uma equipe apaixonada e altamente capacitada, composta por profissionais que combinam experiência e inovação. Trabalhamos juntos para alcançar nossos objetivos e fazer a diferença no setor educacional.</p>
            <p>Estamos comprometidos em criar um impacto positivo e duradouro no mundo da educação. Ao escolher [Nome da Sua Empresa], você está escolhendo a vanguarda da inovação em Gestão Escolar.</p>
            <p><i>Sua Jornada Rumo à Excelência Começa Aqui.</i></p>


<!--          <InstructorArea/>-->
        </div>
      </div>
    </div>
  </section>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header';
import Footer from '../components/Home/Footer.vue';
import Breadcrumb from "@/components/common/Breadcrumb.vue";
// import InstructorArea from "@/components/Instructor/InstructorArea.vue";

export default {
  name:'QuemSomos',
  components:{
    // InstructorArea,
    Breadcrumb,
    Header,
    Footer,
  }
}
</script>

